import React, { useState } from "react";
import "../DocPage/sidebarpage.scss";
import {
  MARK_LINK,
  NODE_HEADING,
  render,
} from "storyblok-rich-text-react-renderer";
import { Link } from "gatsby";
import { getLink, Image, partial_match } from "../../utils/helper";
import WistiaPlayer from "../WistiaPlayer";
import CustomLink from "../CustomLink";

const SourcePage = ({ story }) => {
  const [search_key, setSearchKey] = useState("");
  const content = story?.content;
  const { Content, Headline } = content;

  const setDefaultContent = (name, props) => {
    switch (name) {
      case "Sources_List":
        if (!partial_match(props?.Title, search_key)) {
          return;
        }
        return (
          <div className="source-list">
            <Link to={getLink(props?.Link?.cached_url)}>
              <Image src={props?.Image?.filename} alt={props?.Image?.alt} />
              <h5> {props?.Title} </h5>
            </Link>
          </div>
        );
      case "Quick Start Source":
        return (
          <div className="quick-section">
            <h3> {props?.Title} </h3>
            <div className="inner-quick">
              <div className="quick-left">
                <Image src={props?.Image?.filename} alt={props?.Image?.alt} />
              </div>
              <div className="quick-right">
              <CustomLink {...props.Link}> {props?.Link_Label} </CustomLink>
                <h5> {props?.Description} </h5>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="sidebar-template source-template">
      {/* <Breadcrumb location={location} crumbLabel={Headline} crumbSeparator=" > " breadcrumb__list="" /> */}
      <div className="article-title">
        <h1> {Headline} </h1>
      </div>
      {render(Content, {
        defaultBlokResolver: (name, props) => setDefaultContent(name, props),
        markResolvers: {
          [MARK_LINK]: (children, props) => {
            const { linktype, href, target } = props;
            if (linktype === "email") {
              // Email links: add `mailto:` scheme and map to <a>
              return <a href={`mailto:${href}`}>{children}</a>;
            }
            if (href.match(/^(https?:)?\/\//)) {
              // External links: map to <a>
              return (
                <a href={href} target={target}>
                  {children}
                </a>
              );
            }
            // Internal links: map to <Link>
            return (
              <Link to={getLink(href)}>
                <a>{children}</a>
              </Link>
            );
          },
        },
        blokResolvers: {
          ["search"]: (props) => {
            const { place_holder } = props || {};
            return (
              <div className="source-search">
                <input
                  placeholder={place_holder}
                  value={search_key}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <span className="search-icon"></span>
              </div>
            );
          },
          ["VideoEmbed"]: (props) => {
            return <div dangerouslySetInnerHTML={{ __html: props.source }} />;
          },
          ["wistia_player"]: (props) => {
            return <WistiaPlayer blok={props} key={props._uid} />;
          },
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, props) => {
            switch (props?.level) {
              case 1:
                return (
                  <h1
                  //   id={
                  //     Array.isArray(children) &&
                  //     typeof children[0] === "string" &&
                  //     children[0].replace(/ /g, "_")
                  //   }
                  >
                    {children}
                  </h1>
                );
              case 2:
                return (
                  <h2
                  // id={Array.isArray(children) && children[0].props.children.replace(/ /g, "_") }
                  //   id={
                  //     Array.isArray(children) &&
                  //     typeof children[0] === "string"
                  //       ? children[0].replace(/ /g, "_")
                  //       : children[0]?.props?.children.replace(/ /g, "_")
                  //   }
                  >
                    {children}
                  </h2>
                );
              case 3:
                return (
                  <h3
                  //   id={
                  //     Array.isArray(children) &&
                  //     typeof children[0] === "string" &&
                  //     children[0].replace(/ /g, "_")
                  //   }
                  >
                    {children}
                  </h3>
                );
              case 4:
                return (
                  <h4
                  //   id={
                  //     Array.isArray(children) &&
                  //     typeof children[0] === "string" &&
                  //     children[0].replace(/ /g, "_")
                  //   }
                  >
                    {children}
                  </h4>
                );
              case 5:
                return (
                  <h5
                  //   id={
                  //     Array.isArray(children) &&
                  //     typeof children[0] === "string" &&
                  //     children[0].replace(/ /g, "_")
                  //   }
                  >
                    {children}
                  </h5>
                );
              case 6:
                return (
                  <h6
                  //   id={
                  //     Array.isArray(children) &&
                  //     typeof children[0] === "string" &&
                  //     children[0].replace(/ /g, "_")
                  //   }
                  >
                    {children}
                  </h6>
                );
              default:
                return;
            }
          },
        },
      })}
    </div>
  );
};

export default SourcePage;
